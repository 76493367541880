module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"17","matomoUrl":"https://stats.schueleraustausch.net","siteUrl":"https://www.sprachreisenvergleich.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GN3WZG7W8Z"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-source-ak-locations/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://locations.austauschkompass.de"},
    },{
      plugin: require('../node_modules/gatsby-source-akoffers/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"faab1b79d9546c884aed","apiBaseUrl":"https://offers.austauschkompass.de/api/v1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo-sprv-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3a5c3b0b9d0e899d1ee87b46463f254"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
