exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-with-cities-js": () => import("./../../../src/templates/articleWithCities.js" /* webpackChunkName: "component---src-templates-article-with-cities-js" */),
  "component---src-templates-article-with-offers-for-country-js": () => import("./../../../src/templates/articleWithOffersForCountry.js" /* webpackChunkName: "component---src-templates-article-with-offers-for-country-js" */),
  "component---src-templates-article-with-offers-js": () => import("./../../../src/templates/articleWithOffers.js" /* webpackChunkName: "component---src-templates-article-with-offers-js" */),
  "component---src-templates-article-with-orga-rating-js": () => import("./../../../src/templates/articleWithOrgaRating.js" /* webpackChunkName: "component---src-templates-article-with-orga-rating-js" */),
  "component---src-templates-article-with-organisations-js": () => import("./../../../src/templates/articleWithOrganisations.js" /* webpackChunkName: "component---src-templates-article-with-organisations-js" */),
  "component---src-templates-catalog-order-js": () => import("./../../../src/templates/catalogOrder.js" /* webpackChunkName: "component---src-templates-catalog-order-js" */),
  "component---src-templates-quiz-component-js": () => import("./../../../src/templates/quizComponent.js" /* webpackChunkName: "component---src-templates-quiz-component-js" */)
}

